<template>
  <div class="chart">
       <ApexCharts id='chart' type="area" width="163px" height="40px" ref="chart" :options="option ? blueChartOptions : redChartOptions" :series="series"></ApexCharts>
    </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props:{
      data:Array,
      option: Boolean,
  },
  data(){
      return{
         series: [{
            data: []
          }],
          redChartOptions: {
            chart: {
              height: '100%',
              type: 'area',
                sparkline: {
                    enabled: true,
                }
            },
                stroke: {
                show: false,
                width: 2,
                curve: 'smooth'
            },
            tooltip:{
                enabled:false
            },
            
          
           
                 fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        colorStops: [ [
                            {
                                offset: 0,
                                color: 'rgba(232, 48, 81, 0.57)',
                                opacity: 1
                            },{
                                offset:100 ,
                                color: 'rgba(250, 17, 213, 0)',
                                opacity: 1
                            },
                        ]],
                        shadeIntensity: 1,
                        type: 'vertical',
                        opacityFrom: 1,
                        opacityTo: 1,
                       
                    },
            },
        
      
   
          },
          blueChartOptions: {
            chart: {
              height: '100%',
              type: 'area',
                sparkline: {
                    enabled: true,
                }
            },
                stroke: {
                show: false,
                width: 2,
                curve: 'smooth'
            },
            tooltip:{
                enabled:false
            },
            
          
           
                 fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        colorStops: [ [
                            {
                                offset: 0,
                                color: 'rgba(110, 156, 255, 0.57)',
                                opacity: 1
                            },{
                                offset:100 ,
                                color: 'rgba(110, 156, 255, 0)',
                                opacity: 1
                            },
                        ]],
                        shadeIntensity: 1,
                        type: 'vertical',
                        opacityFrom: 1,
                        opacityTo: 1,
                       
                    },
            },
        
      
   
          },
      }
  },
  mounted(){
      this.series=[{
          data:this.data
      }]
  }
//   mounted: function () {
//         //   var me = this
//           window.setInterval(function () {
//             getNewSeries(lastDate, {
//               min: 10,
//               max: 90
//             })
            
//             this.$refs.chart.updateSeries([{
//               data: this.data
//             }])
//           }, 1000)
        
//           // every 60 seconds, we reset the data to prevent memory leaks
//           window.setInterval(function () {
            
//             this.$refs.chart.updateSeries([{
//               data:this.data
//             }], false, true)
//           }, 60000)
//         },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
</style>