<template>
    <div class="markett-container">
        <h3> همه رمز ارز ها </h3>
        <Market :coinsToman="coinsToman" :coinsTether="coinsTether" />
    </div>
</template>

<script>
import Market from '../HomePage/Market.vue'
    export default {
        name:'MarketPage',
            components: { Market },
            data() {
                return {
                    coinsToman:[],
                    coinsTether:[]
                }
            },
            methods:{
                async getCoins() {
                    this.state.loading = true
                    const res = await this.$axios.get('/trades/market-info',{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    this.coinsToman = res.content.filter(a => a.marketType.includes('TOMAN'))
                    this.coinsTether = res.content.filter(a => a.marketType.includes('TETHER'))
                    console.log(this.coinsToman,this.coinsTether);
                },
            },
            mounted() {
            this.getCoins();  
            },
        
    }
</script>

<style lang="scss" scoped>
h3{
    color: var(--black);
    font-weight: 700;
    font-size: clamp(28px,4vw,36px);
    margin-right: 80px;
}
.markett-container{
    padding: 133px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    background: var(--homepage-bg);
    position: relative;
    &::after{
        content: ' ';
        background: var(--red);
        filter: blur(400px);
        opacity: .05;
        filter: drop-shadow(0 0 0.1rem var(--red));
        box-shadow: 0px 0px 300px 100px var(--red);
        background-size:cover;
        background-repeat: repeat-x;
        position: absolute;
        height: 100px;
        border-radius: 50%;
        width: 10%;
        left: 0;
        top: 0px;
    }
}

</style>