<template>
    <div class="market-container">
        <div class="title">
            <h2> نگاه کلی به بازار </h2> 
            <span> در ۲۴ ساعت گذشته </span>
        </div>
        <div class="table">
            <table>
                <thead>
                    <tr>
                        <td style="text-align:start;padding-right:20px"> رمز ارز </td>
                        <td class="remove2"> قیمت لحظه ای ({{toman ? 'تومان':'تتر'}}) </td>
                        <td> تغییرات </td>
                        <td class="remove"> حجم </td>
                        <td style="text-align:center;" class="remove"> نمودار </td>
                        <td style="text-align:end;display:flex;justify-content:end;padding-left:20px;"> 
                            <div class="pricebtns">
                                <button @click="toman=true" :class="{'select':toman}" class="toman"> تومان </button>
                                <button @click="toman=false" :class="{'select':!toman}" class="tether"> تتر </button>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr @click.prevent="reDirect(coin.relatedCoin)" class="coin-row" v-for="(coin,index) in coins" :key="index">
                        <td style="text-align:start;padding-right:20px">
                            <div class="coin">
                                <img :src="'/coins/'+$coin2Snake[coin.relatedCoin]+'.png'" alt="">
                                <div class="unit remove2">
                                    <span class="faunit">
                                        {{$coinLabel[coin.relatedCoin]}}
                                    </span>
                                    <span class="enunit">
                                        {{coin.relatedCoin}}
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>
                            {{$toLocal(coin.lastPrice,(toman ? 0:2))}}
                        </td>
                        <td class="remove2"> 
                            <span v-if="coin.last24HourChange > 0" class="success"> 
                                {{$toLocal(coin.last24HourChange,2)}}%
                                <inline-svg class="success rotate" :src="require('../../assets/Icons/arrow.svg')" />
                            </span>
                            <span v-if="coin.last24HourChange < 0" class="fail"> 
                                {{$toLocal(Math.abs(coin.last24HourChange),2)}}% 
                                <inline-svg class="fail"  :src="require('../../assets/Icons/arrow.svg')" />
                            </span>  
                        </td>
                        <td class="remove"> {{$toLocal(coin.last24HourVolume,$decimal[$coin2Snake[coin.relatedCoin]])}} </td>
                        <td class="remove">
                            <MarketChartApex :data="coin.lastWeekData" :option="coin.last24HourChange > 0 ? true : false" />
                        </td>
                        <td>
                            <div class="td-btn">
                                <button class="submit"> معامله </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <a v-if="$route.name !== 'Market'" @click.prevent="$router.push('/market')" href="/market" class="marketsend"> مشاهده همه رمز ارز ها </a>
    </div>
</template>

<script>
import MarketChartApex from '../Tools/MarketChartApex.vue'
    export default {
        name:'Market',
        components:{
            MarketChartApex
        },
        props:['coinsToman','coinsTether'],
        data() {
            return {
                toman:false,
                coinn:{
                        buyPrice: 1759047.192,
                        id: null,
                        last24HourChange: 0.62,
                        last24HourHighestPrice: 0,
                        last24HourOrders: 0,
                        last24HourTrades: 0,
                        last24HourVolume: 0,
                        lastPrice: 1752912,
                        lastPriceOfYesterday: 0,
                        lastTradeCreatedDateTime: null,
                        lastWeekData: [100,200,10000,1200,4500,6700,10,40,1],
                        lastWeekHighestPrice: 0,
                        marketType: "LITE_COIN_TOMAN",
                        maxPrice: 1792700,
                        meanPriceOfLast24Hour: 0,
                        minPrice: 1708224,
                        relatedCoin: "LTC",
                        sellPrice: 1746776.808,
                    }
            }
        },
        computed:{
            coins(){
                let coinss
                if(this.toman){
                    coinss=this.coinsToman
                }else{
                    coinss=this.coinsTether.filter(a => a.relatedCoin !== 'USDT')
                }
                return coinss
            }
        },
        methods:{
            reDirect(to){
                if(this.toman){
                    this.$router.push('/advancetrade/TOMAN/'+this.$coin2Snake[to])
                }else{
                    this.$router.push('/advancetrade/TETHER/'+this.$coin2Snake[to])
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.coin-row{
    transition: 200ms;
    &:hover{
    background: #F0F0F5;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 8px;
    cursor: pointer;
    }
}
.enunit{
    color: var(--a-hover) !important;
    font-weight: 400 !important;
    font-size: clamp(10px,1vw,12px) !important;
}
.marketsend{
    color: var(--primary);
    align-self: flex-end;
    padding-left: 20px;
    // margin-left: 100px;
}
.td-btn{
    display: flex;
    justify-content: end;
    align-items: flex-end;
    padding-left: 20px;
    button{
        width: 100%;
        max-width: 130px;
    }
}
.coin{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    img{
        width: 36px;
        height: 36px;
    }
    .unit{
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }
}
.select{
    color: var(--primary) !important;
    background: var(--primary-light);
}
.pricebtns{
    display: flex;
    flex-direction: row;
    padding: 6px;
    background: var(--white);
    transition: 700ms;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
    max-width: 180px;
    button{
        color: var(--a-hover);
        font-weight: 500;
        font-size: clamp(10px,1vw,12px);
        // width: 100% !important;
        // max-width: 130px !important;
    }
}
.table{
    height: 100%;
    width: 100%;
    table{
        width: 100%;
        tr{
            height: 76px;
        }
        thead{
            height: 37px;
            td{
                text-align: start;
                font-weight: 700;
                font-size: clamp(14px,2vw,16px);
                color: var(--black);
            }
        }
    }
}
.title{
    h2{
        font-weight: 700;
        font-size: clamp(16px,2vw,20px);
    }
    span{
        font-weight: 700;
        font-size: clamp(12px,2vw,14px);
        color: var(--a-default);
    }
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    column-gap: 8px;
}
.market-container{
    padding: 40px 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 36px;
    justify-content: center;
    background: url('../../assets/bubble/charts.png');
    background-repeat: no-repeat;
    background-size: 100% 80%;
    background-position: center;
}
@media only screen and(max-width:1000px){
    .market-container{
        padding: 20px;
        align-items: center;
        justify-content: center;
    }
    .remove{
        display: none;
    }
}
@media only screen and(max-width:800px){
    .remove2{
        display: none !important;
    }
}
</style>